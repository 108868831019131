import { queryCityLatLngList } from '@/api/pledge';


const cityLaction = {
  state: {
    // cityLaction: []
    cityLaction: JSON.parse(localStorage.getItem('cityLaction')) || {}
  },
  mutations: {
    getQueryCityLatLngList(state, cityLaction) {
      state.cityLaction = cityLaction
      localStorage.setItem('cityLaction', JSON.stringify(cityLaction))
    }
  },
  actions: {
    getQueryCityLatLngList({ commit }, cityList) {
      let arr = []
      let params = {
        state: 2,
        current: 1,
        size: 999999
      }
      let obj = {

      }
      queryCityLatLngList(params).then(res => {//获取经纬度列表
        arr = res.records.map(item => {
          item.laction = {
            lng: item.lpLng,
            lat: item.lpLat
          }
          obj[item.cityCode] = item.laction
          return item
        })
       localStorage.setItem('cityLaction', JSON.stringify(obj))
        commit('getQueryCityLatLngList', obj)
        // commit('getQueryCityLatLngList', arr)
      })
    }
  }
}
export default cityLaction;