<template>
  <div class="all-box">
    <div class="input-box">
      <!-- <el-input placeholder="请输入内容" style=" border-radius: 4px 0px 0px 4px;" v-model="input3" class="input">
        <i slot="prefix" class="el-input__icon el-icon-search"></i>
      </el-input> -->
      <Select ref="selectCityRef" v-model="city" @on-change="cityChange" clearable filterable placeholder="请输入" @on-query-change="queryChange">
        <Option v-for="item in cityList" :value="item.cityCode" :disabled="!item.isAuth" :label="item.cityFullName"
          :key="item.cityCode">
          <div style="display: flex;">
            <div @click="clickCity" style="width: 50px;">{{ item.cityFullName }} </div>
            <div class="nocity-authority" style="width: 100px;" v-if="!item.isAuth">您尚无此授权</div>
          </div>
        </Option>
      </Select>
      <el-button class="bit" style=" border-radius: 0px 4px 4px 0px;" type="primary">搜索</el-button>
    </div>
    <div class="hot-city">
      <div class="title">最近搜索</div>
      <div class="city-list">
        <div class="city-item" v-for="(item, index) in historyCity" :key="index">
          <span v-if="item.isAuth" class="history-city" @click="selectCity(item.cityCode)"> {{ item.cityFullName }}
            <Icon type="ios-close-circle-outline" class="history-close" @click.stop="removeCity(item.cityCode)" />
          </span>
          <Tooltip content="您尚无此授权" placement="top">
            <span v-if="!item.isAuth" class="history-city" style="color: #c5c8ce;"> {{ item.cityFullName }}
              <Icon type="ios-close-circle-outline" class="history-close" style="right: -17px;" @click="removeCity(item.cityCode)" />
            </span>
          </Tooltip>
        </div>
      </div>
    </div>
    <div class="hot-city" style="margin-top: 20px;">
      <div class="title">热门城市</div>
      <div class="city-list">
        <div class="city-item" v-for="(item, index) in hotCity" :key="index">
          <span v-if="item.isAuth" @click="selectCity(item.cityCode)"> {{ item.cityFullName }}</span>
          <Tooltip content="您尚无此授权" placement="top">
            <span v-if="!item.isAuth" style="color: #c5c8ce;"> {{ item.cityFullName }}</span>
          </Tooltip>
        </div>
      </div>
    </div>
    <div class="hot-city" style="margin-top: 20px;">
      <div class="title">选择城市</div>
      <div>
        <Select v-model="province" placeholder="选择省份" @on-change="clickProvince" style="width: 198px;">
          <Option v-for="item in provinceList" :value="item.cityCode" :label="item.name"
            :key="item.provinceCode">
            <div style="display: flex;">
              <div style="width: 50px;">{{ item.name }} </div>

            </div>
          </Option>
        </Select>
        <Select v-model="singleCity" placeholder="选择城市" style="width: 198px; margin-left: 13px;">
          <Option v-for="item in singleCityList" :value="item.cityCode" :disabled="item.userAuthFlag==2"
            :label="item.name" :key="item.cityCode">
            <div style="display: flex;">
              <div @click="selectCity(item.cityCode)" style="width: 50px;">{{ item.name }} </div>
              <div class="nocity-authority" style="width: 100px;" v-if="item.userAuthFlag==2">您尚无此授权</div>
            </div>
          </Option>
        </Select>
      </div>
    </div>
    <div class="nations">
      <div class="provinces-box" v-for="(item1,index) in provinceList" :key="index">
        <div class="province-box">{{ item1.name }}
          <div class="triangle-left-solid"></div>
        </div>
        <div class="city-box">
          <span v-for="item in item1.cityList" :value="item.cityCode" :key="item.cityCode" class="city-box-item">
            <span v-if="item.userAuthFlag==1" @click="selectCity(item.cityCode)"> {{ item.name }}</span>
            <Tooltip content="您尚无此授权" placement="top">
              <span v-if="item.userAuthFlag==2" style="color: #c5c8ce;"> {{ item.name }}</span>
            </Tooltip>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { queryHotCity,upCityConfigList,getUserAuthCityTree } from '@/api/pledge'

export default {
  data() {
    return {
      city: '',
      cityList: [],
      historyCity: [],
      hotCity: [
        // {
        //   "cityCode": "440100",
        //   "cityFullName": "广州市",
        //   "cityShortName": "广州",
        //   "isAuth": true,
        //   "provinceCode": "44",
        //   "provinceFullName": "广东省",
        //   "provinceShortName": "广东"
        // },
        // {
        //   "cityCode": "440100",
        //   "cityFullName": "广州市",
        //   "cityShortName": "广州",
        //   "isAuth": true,
        //   "provinceCode": "44",
        //   "provinceFullName": "广东省",
        //   "provinceShortName": "广东"
        // },
        // {
        //   "cityCode": "440100",
        //   "cityFullName": "广州市",
        //   "cityShortName": "广州",
        //   "isAuth": true,
        //   "provinceCode": "44",
        //   "provinceFullName": "广东省",
        //   "provinceShortName": "广东"
        // },
        // {
        //   "cityCode": "440100",
        //   "cityFullName": "广州市",
        //   "cityShortName": "广州",
        //   "isAuth": true,
        //   "provinceCode": "44",
        //   "provinceFullName": "广东省",
        //   "provinceShortName": "广东"
        // },
        // {
        //   "cityCode": "440100",
        //   "cityFullName": "广州市",
        //   "cityShortName": "广州",
        //   "isAuth": true,
        //   "provinceCode": "44",
        //   "provinceFullName": "广东省",
        //   "provinceShortName": "广东"
        // },
        // {
        //   "cityCode": "440100",
        //   "cityFullName": "广州市",
        //   "cityShortName": "广州",
        //   "isAuth": true,
        //   "provinceCode": "44",
        //   "provinceFullName": "广东省",
        //   "provinceShortName": "广东"
        // },
        // {
        //   "cityCode": "440100",
        //   "cityFullName": "广州市",
        //   "cityShortName": "广州",
        //   "isAuth": true,
        //   "provinceCode": "44",
        //   "provinceFullName": "广东省",
        //   "provinceShortName": "广东"
        // },
        // {
        //   "cityCode": "440100",
        //   "cityFullName": "广州市",
        //   "cityShortName": "广州",
        //   "isAuth": true,
        //   "provinceCode": "44",
        //   "provinceFullName": "广东省",
        //   "provinceShortName": "广东"
        // },
        // {
        //   "cityCode": "440100",
        //   "cityFullName": "广州市",
        //   "cityShortName": "广州",
        //   "isAuth": true,
        //   "provinceCode": "44",
        //   "provinceFullName": "广东省",
        //   "provinceShortName": "广东"
        // },
        // {
        //   "cityCode": "440100",
        //   "cityFullName": "广州市",
        //   "cityShortName": "广州",
        //   "isAuth": true,
        //   "provinceCode": "44",
        //   "provinceFullName": "广东省",
        //   "provinceShortName": "广东"
        // },
        // {
        //   "cityCode": "440100",
        //   "cityFullName": "广州市",
        //   "cityShortName": "广州",
        //   "isAuth": true,
        //   "provinceCode": "44",
        //   "provinceFullName": "广东省",
        //   "provinceShortName": "广东"
        // },
      ],
      provinceList: [],//省份
      province: '',
      // singleCityList: [],//单城市
      singleCity: '',
      userAutoCityList: JSON.parse(localStorage.getItem('userAutoCityList')),
    };
  },
  computed: {
    singleCityList() {
      return this.provinceList.find(item => item.cityCode === this.province) ? this.provinceList.find(item => item.cityCode === this.province).cityList : []
    }
    // historyCity(){
    // return  localStorage.getItem('historyCity') ? JSON.parse(localStorage.getItem(
    //       'historyCity')) : []
    // }
  },
  created() {
    this.cityList = JSON.parse(localStorage.getItem('userAutoCityList'))
    this.singleCity = ''
    this.province = ''
    this.historyCity = localStorage.getItem('historyCity') ? JSON.parse(localStorage.getItem(
      'historyCity')) : []
  },
  mounted() {
  this.getupCityConfigList()
    this.getHotCityList()
  },
  methods: {
  //获取所有省份城市
async getupCityConfigList(){
let params = {
origin:1
}
  let res = await getUserAuthCityTree(params)
this.provinceList = res
},
async getHotCityList() {
  let res = await queryHotCity();
  this.hotCity = res.map(item => {
    item.cityFullName = item.cityName;
    // item.isAuth = true;
    return item;
  });

  // 使用对象字面量来优化匹配过程
  let cityCodeMap = {};
  this.cityList.forEach(item => {
    cityCodeMap[item.cityCode] = item.isAuth;
  });

  this.hotCity.forEach(k => {
    k.isAuth = cityCodeMap[k.cityCode] || false;
  });
},


    queryChange(query) {
      //   if (query) {
      // this.cityList = this.userAutoCityList
      //   }else{
      //   this.cityList  = this.historyCity
      //   }
    },
    //将城市按照省份分组
    groupByProvinceCode(array) {
      return array.reduce((acc, item) => {
        const existingGroup = acc.find(group => group.provinceCode === item.provinceCode);
        if (existingGroup) {
          existingGroup.child.push(item);
        } else {
          acc.push({
            provinceCode: item.provinceCode,
            provinceFullName: item.provinceFullName,
            provinceShortName: item.provinceShortName,
            child: [item]
          });
        }
        return acc;
      }, []);
    },
    clickProvince(provinceCode) {
      // let province = this.provinceList.find(item => item.provinceCode == provinceCode)
      // this.singleCityList = province.child
      // console.log('province', province)
    },


    selectCity(cityCode) {
      // this.cityChange(cityCode)
      this.city = ''
        let obj = this.cityList.find((item1) => item1.cityCode == cityCode)
      let historyCity = localStorage.getItem('historyCity') ? JSON.parse(localStorage.getItem(
        'historyCity')) : []
      historyCity.unshift(obj)
      historyCity = this.uniqueFunc(historyCity, 'cityCode')
      historyCity = historyCity.slice(0, 5)
      localStorage.setItem('historyCity', JSON.stringify(historyCity))
      this.historyCity = localStorage.getItem('historyCity') ? JSON.parse(localStorage.getItem(
        'historyCity')) : []
      this.$emit('cityChange', obj)
    //   setTimeout(() => {
    //     this.singleCity = ''
    // this.province = ''
    //   }, 500)


    },
    cityChange(cityCode) {
      let obj = this.cityList.find((item1) => item1.cityCode == cityCode)
      let historyCity = localStorage.getItem('historyCity') ? JSON.parse(localStorage.getItem(
        'historyCity')) : []
      historyCity.unshift(obj)
      historyCity = this.uniqueFunc(historyCity, 'cityCode')
      historyCity = historyCity.slice(0, 5)
      localStorage.setItem('historyCity', JSON.stringify(historyCity))
      this.historyCity = localStorage.getItem('historyCity') ? JSON.parse(localStorage.getItem(
        'historyCity')) : []
      this.$emit('cityChange', obj)
      //       this.singleCity = ''
      // this.province = ''
    },
    removeCity(cityCode) {
      let historyCity = localStorage.getItem('historyCity') ? JSON.parse(localStorage.getItem(
        'historyCity')) : []
      historyCity = historyCity.filter((item) => item.cityCode != cityCode)
      localStorage.setItem('historyCity', JSON.stringify(historyCity))
      this.historyCity = localStorage.getItem('historyCity') ? JSON.parse(localStorage.getItem(
        'historyCity')) : []
    },
    clickCity() {
      // this.$emit('clickCity', this.city)
    },
    uniqueFunc(arr, value) { //数组去重
      const res = new Map();
      return arr.filter((item) => !res.has(item[value]) && res.set(item[value], 1));
    },
  }
};
</script>

<style scoped lang="scss">
/deep/ .input-box .ivu-select-selection {
  height: 40px;
}

/deep/ .input-box .ivu-select-placeholder {
  height: 40px;
  line-height: 42px;
}

/deep/ .ivu-select-input {
  height: 38px;

}

.history-city {

  // display: block;
  &:hover .history-close {
    display: block;

  }
}


.history-close {
  display: none;
  position: absolute;
  right: 23px;
  top: -4px
}

.all-box {
  width: 604px;
  // height: 694px;
  // min-height: 694px;
  padding: 48px;
  background: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  z-index: 999;

  .input-box {
    display: flex;
    justify-content: center;

    .input {
      width: 438px;
      // margin: auto;
      // margin:48px
    }
  }



  .hot-city {
    display: flex;
    // align-items: center;
    margin-top: 48px;
    vertical-align: top;

    .city-list {
      display: flex;
      flex: 1;
      flex-wrap: wrap;

      .city-item {
        cursor: pointer;
        width: 20%;
        position: relative;
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: normal;
        // line-height: 16px;
        margin-bottom: 24px;
        // margin-right: 30px;

        font-variation-settings: "opsz" auto;
        color: #262626;
      }
    }
  }

  .title {
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 700;
    width: 75px;
    height: 100%;
    // line-height: 16px;
    font-variation-settings: "opsz" auto;
    color: #404040;
    margin-right: 24px;
  }

  .nations {
    margin-top: 32px;
    max-height: 600px;
    overflow-y: scroll;

    .provinces-box {
      display: flex;
      align-items: center;
      height: 100%;
      border: 1px solid #ebeef5;
      flex: 0 0 auto;
      cursor: pointer;

      .province-box {
        width: 88px;
        font-family: PingFang SC;
        font-size: 18px;
        font-weight: 700;
        // line-height: 16px;
        text-align: center;
        font-variation-settings: "opsz" auto;
        color: #262626;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;

        .triangle-left-solid {
          position: absolute;
          visibility: hidden;
          right: 0;
          width: 0;
          height: 0;
          border-top: 6px solid transparent;
          border-right: 6px solid #fff;
          border-bottom: 6px solid transparent;
        }
      }

      &:hover {
        background-color: #0077FF;
        // border: 0
      }

      &:hover .triangle-left-solid {
        visibility: visible;
      }

      &:hover .province-box {
        color: #fff;
      }

      &:hover .city-box {
        background-color: #F3F8FF;
        //  border: 1px solid #F3F8FF;
      }

      .city-box {
        flex: 1;
        display: flex;
        padding: 24px;
        padding-right: 0px;
        flex-wrap: wrap;
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: normal;
        // line-height: 16px;
        letter-spacing: 0em;
        padding-left: 0px;
        font-variation-settings: "opsz" auto;
        color: #262626;
        border-left: 1px solid #ebeef5;
        height: 100%;

        .city-box-item {
          // margin-right: 24px;
          margin: 12px;

          &:hover {
            color: #0077FF;
            cursor: pointer;
          }
        }
      }
    }
  }

}
</style>
