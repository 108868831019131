<template>
	<Modal v-model="showFeedback" width="895" :footer-hide="true">
		<p slot="header" style="">
			<span>您的建议</span>
		</p>
		<div style="padding: 0 120px; margin-bottom: 140px;">
			<Form :model="form" :rules="ruleValidate" label-position="right" :label-width="120">
				<FormItem label="您期望的单价" prop="evaluatePrice">
					<el-input v-model="form.evaluatePrice" maxlength="10" @change="unitPriceChange"
						onkeyup="value=value.replace(/^0+(\d)|[^\d]+/g,'')" style="width: 485px;"><span
							slot="append">元/平方米</span></el-input>
				</FormItem>
				<FormItem label="您期望的总价" prop="evaluateTotalPrice">
					<el-input v-model="form.evaluateTotalPrice" maxlength="15" @change="totalPriceChange"
						onkeyup="value=value.replace(/^0+(\d)|[^\d]|/^(\d+.?)?\d{0,2}+/g,'')" style="width: 485px;"><span
							slot="append">万元</span></el-input>
				</FormItem>
				<FormItem label="建议">
					<el-input maxlength="100" type="textarea" style="width: 485px;" :rows="5" show-word-limit
						v-model="form.reason"></el-input>
				</FormItem>
				<FormItem>
					<Button type="primary" style="width: 485px; height: 44px;"
						v-track="{ event: 'click', route: $route, isButton: true, log: '用户反馈-提交' }"
						@click="ok(FeedbackState)">提交</Button>
				</FormItem>
			</Form>
		</div>
		<!-- <div slot="footer">
			<Button type="success" v-track="{event:'click',route:$route,isButton:true,log:'用户反馈-提交'}"
				@click="ok(FeedbackState)">提交</Button>
		</div> -->
	</Modal>
</template>

<script>
import {
	addDataUserFeedback
} from '@/api/pledge.js'
import {
	data_user_operation_log_in
} from '@/api/operationLog.js'
export default {
	props: {
		feedbackModel: {
			type: Boolean,
			required: true,
		},
		feedbackObj: {
			type: Object
		},
		feedbackState: {
			type: Number
		},
		buildingArea: {//面积
			type: Number,
			default: 0,
		},
	},
	data() {
		let _this = this
		return {
			form: {
				evaluatePrice: '',
				evaluateTotalPrice: '',
				reason: ''
			},
			FeedbackState: 0,
			ruleValidate: {
				evaluatePrice: [
					{ required: true, message: '请输入单价', trigger: 'blur' },

				],
				evaluateTotalPrice: [
					{ required: true, message: '请输入总价', trigger: 'blur' },
				],

			},
		}
	},
	computed: {
		showFeedback: {
			get() {
				return this.feedbackModel
			},
			set(newVal) {
				this.$parent.showFeedbackModal = newVal
			}
		},
	},
	watch: {
		feedbackObj: {
			handler(newVal, oldVal) {
				this.form.evaluatePrice = (Number(newVal.evaluatePrice) * 1).toFixed(0)
				this.form.evaluateTotalPrice = (Number(newVal.evaluateTotalPrice) * 1).toFixed(2)
			},
			deep: true,
		},
		showFeedback(newVal) {
			if (newVal) {

				this.form.evaluatePrice = (Number(this.feedbackObj.evaluatePrice) * 1).toFixed(0)
				this.form.evaluateTotalPrice = (Number(this.feedbackObj.evaluateTotalPrice) * 1).toFixed(2)
			}
		},
		feedbackState(val) {
			this.FeedbackState = val
			this.form.reason = ''
		}
	},
	methods: {
		unitPriceChange(val) {// 单价改变
			this.form.evaluateTotalPrice = (val * this.buildingArea / 10000).toFixed(2)
		},
		totalPriceChange(val) {// 总价改变
			let num = Number(val)
			if (isNaN(num)) {
				this.form.evaluateTotalPrice = ''
				return

			}
			if (num<0) {
				this.form.evaluateTotalPrice = ''
				return
			}
			this.form.evaluatePrice = ((val * 10000) / this.buildingArea).toFixed(0)

		},
		async ok(value) {

			if (this.FeedbackState !== 0) {
				if (this.form.evaluatePrice == '') {
					this.$msg.error({text: "请输入单价"} );
					return
				}
				if (this.form.evaluateTotalPrice == '') {
					this.$msg.error({text: "请输入总价"} );
					return
				}
				// let logText = ''

				// if (value === -1) {
				// 	logText = '偏低'
				// } else if (value === 0) {
				// 	logText = '合理'

				// } else if (value === 1) {
				// 	logText = '偏高'
				// }
				// let that = this
				// let _params = {
				// 	event: 'click',
				// 	isButton: true,
				// 	log: logText,
				// 	os: navigator.userAgent,
				// 	platform: '数据平台PC',
				// 	route: that.$route.path,
				// 	title: that.$route.meta.title,
				// 	token: that.$store.state.token ? that.$store.state
				// 		.token : '',
				// 	user_id: that.$store.state.userId ? that.$store
				// 		.state
				// 		.userId : '',
				// }

				// data_user_operation_log_in(_params)
				let params = {
					cityCode: this.feedbackObj.cityCode,
					// assessBaseDate: this.feedbackObj.evaluateBasicDate,
					feedbackReason: this.form.reason,
					feedbackStates: value,
					feedbackTotalPrice: (this.form.evaluateTotalPrice * 1).toFixed(0),
					feedbackUnitPrice: (this.form.evaluatePrice * 1).toFixed(0),
					sampleHistoryId: this.feedbackObj.sampleHistoryId,
					certificateMd5: this.feedbackObj.certificateMd5,
					platform: '1'
				}

				addDataUserFeedback(params).then(res => {
					// this.$msg.success({
					// 	text: '谢谢'
					// });
					this.$msg.success({text: "谢谢！" } );
					this.$emit('feedbackOk')
					this.showFeedback = false
				}).catch(err => {
					this.$msg.error({
						text: this.$errorCode[err.code]
					});
				})
			}
		},
		show() {

		}
	}

}
</script>

<style scoped>

</style>
