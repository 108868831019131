<template>
  <div class="body-box" v-track="{ event: 'browse', route: $route, isButton: false }" v-citypermission>
    <div class="main-content">
      <Row :gutter="16" index="">
        <Col span="11">
        <!-- <div>
          <Select v-model="city" @on-change="cityChange" style="width:112px;box-shadow: 0px 3px 8px 1px rgba(38,38,38,0.1);
                border-radius: 4px 4px 4px 4px;">
            <Option v-for="item in cityList" :value="item.cityCode" :disabled="!item.isAuth" :label="item.cityFullName"
              :key="item.cityCode">
              <div style="display: flex;">
                <div @click="clickCity" style="width: 50px;">{{ item.cityFullName }} </div>
                <div class="nocity-authority" style="width: 100px;" v-if="!item.isAuth">您尚无此授权</div>
              </div>
            </Option>
          </Select>
        </div> -->
        <CityInput @childCityChange="childCityChange" ref="CityInputRef" />
        <!-- <CitySelectBox v-if="true"></CitySelectBox> -->
        <div>
        </div>
        <div>
          <div class="tabs-style">
            <Tabs class="tabs" v-model="tabValue" @on-click="tabClick">
              <TabPane label="小区估价" name="小区估价">
                <div class="tab1" :style="{ 'height': tabHeight }">
                  <BaseForm :faForm="communityForm" @errMsg="clickCommunityErrMsg" :isShowMoreAndNot="communityErrMsg"
                    ref="baseForm">
                    <FormItem label="小区" prop="communityName">
                      <ComInput :isCommunity="true" placeholder="请输入小区名称" ref="comInputCommunity" @noLook="noLook"
                        :faInput="communityName" @basedata="getComData">
                      </ComInput>
                      <div class="map-text" v-track="{ event: 'click', route: $route, isButton: true, log: '地图估价' }"
                        @click="goMap">
                        <img src="../../assets/image/pledge/minMap.png"
                          style="position: absolute;left: -18px; top: 6px;" alt="" srcset="">
                        地图估价
                      </div>
                    </FormItem>
                    <FormItem label="楼栋" prop="street">
                      <el-autocomplete @blur="streetblur"
                        v-track="{ event: 'click', route: $route, isButton: false, log: '搜索-楼栋号' }" class="inline-input"
                        v-model="street" size="small" style="width: 485px; " :maxlength="50"
                        :fetch-suggestions="ldQuerySearch" placeholder="请选择或输入楼栋号" @select="ldHandleSelect">
                      </el-autocomplete>
                      <!-- <el-select v-model="street"  style="width: 485px; "  @blur="streetblur" size="small"  filterable placeholder="请选择或输入楼栋号"   v-track="{ event: 'click', route: $route, isButton: false, log: '搜索-楼栋号' }">
                        <el-option v-for="item in streetList" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                      </el-select> -->
                    </FormItem>
                    <FormItem label="房号" prop="houseNumber">
                      <el-autocomplete @blur="houseNumberblur"
                        v-track="{ event: 'click', route: $route, isButton: false, log: '搜索-房号' }" class="inline-input"
                        v-model="houseNumber" size="small" style="width: 485px; " :maxlength="8"
                        :fetch-suggestions="houseQuerySearch" placeholder="请选择或输入房号" @select="houseHandleSelect">
                      </el-autocomplete>
                    </FormItem>
                  </BaseForm>
                </div>
                <div class="bottom-box">
                  <Checkbox v-model="single">
                    <span @click="showAdviceBookModel = true">我已阅读 <span style="color:#0077FF">《房屋智能估价系统使用声明》</span>
                    </span>
                  </Checkbox>
                  <Button class="bottom-bit" type="primary"
                    v-track="{ event: 'click', route: $route, isButton: true, log: '现在估价' }"
                    :loading="assessmentLoading" @click="assessment" style="width: 485px;">现在估价</Button>
                </div>


              </TabPane>
              <TabPane label="地址估价" name="地址估价" :disabled="addressDisabled">
                <div class="tab1" style="min-height: 220px;">
                  <BaseForm :faForm="addressForm" ref="baseAddressForm" @errMsg="clickCertAddressErrMsg"
                    :isShowMoreAndNot="certAddressErrMsg">
                    <FormItem label="地址" prop="certAddress">
                      <ComInput :isCommunity="false" placeholder="请输入房屋地址" :faInput="certAddress" @noLook="creatNoLook"
                        ref="comInput" @basedata="getCreatData"></ComInput>
                    </FormItem>
                  </BaseForm>
                </div>

                <div class="bottom-box">
                  <Checkbox v-model="single">
                    <span @click="showAdviceBookModel = true">我已阅读 <span style="color:#0077FF">《房屋智能估价系统使用声明》</span>
                    </span>
                  </Checkbox>
                  <Button class="bottom-bit" type="primary"
                    v-track="{ event: 'click', route: $route, isButton: true, log: '现在估价' }"
                    :loading="assessmentLoading" @click="addressValue" style="width: 485px;">现在估价</Button>
                </div>
              </TabPane>
            </Tabs>
          </div>
        </div>
        </Col>
        <Col span="13">
        <div class="Bmap" id="Bmap"></div>
        </Col>
      </Row>
    </div>
    <adviceBookModel :adviceBookModel='showAdviceBookModel' @closeModel="closeModel"></adviceBookModel>
    <HandSubmit :handModel='showHandModel' :fcommunityName="communityName" :fcertAddress="certAddress" :fcity="city">
    </HandSubmit>
    <InfoSubmit :infoModel='showinfoModel' :isCommunity="isCommunity" :fcommunityName="communityName"
      ref="infoSubmitRef" :fcertAddress="certAddress" :ldCode="ldCode" :fldShortName="street" :infoObj="InfoSubmitObj"
      :fcity="city">
    </InfoSubmit>
  </div>
</template>
<script>
import { communitySelectCondition, communitySearchHistory, getStreet, getHouseNumber, collectHouseAttribute, sampleResultDetail, certificateEdit, checkAuth } from '@/api/pledge'
import BMap from "BMap";
import dataReports from "@/mixins/dataReports";

import adviceBookModel from '@/components/adviceBookModel.vue'
import BaseForm from '@/views/pledge/components/BaseForm.vue'
import HandSubmit from '@/views/pledge/components/HandSubmit.vue'
import ComInput from '@/views/pledge/components/ComInput.vue'
import CityInput from '@/views/pledge/components/CityInput.vue'
import CitySelectBox from '@/views/pledge/components/CitySelectBox.vue'

import InfoSubmit from '@/views/pledge/components/InfoSubmit.vue'
import _ from 'lodash'
import CityList from './cityList.js'
import cityLaction from './cityLaction.js'
import {
  data_user_operation_log_in
} from '@/api/operationLog.js'
import {
  formatTime,
  formatDate,
  _debounce,

} from "@/utils/";
export default {
  name: 'rapidValuation',
  mixins: [dataReports],
  components: {
    BaseForm,
    adviceBookModel,
    HandSubmit,
    ComInput,
    InfoSubmit,
    CityInput,
    // CitySelectBox
  },
  data() {
    return {
      tabValue: '小区估价',
      // CityLaction: cityLaction,//城市市政府对应经纬度
      addressDisabled: false,//地址估价是否禁用
      isCommunity: true,//是否是小区
      communityErrMsg: false,//是否显示信息有误字样
      certAddressErrMsg: false,//是否显示信息有误字样
      communityName: '',
      certAddress: '',
      assessmentLoading: false,
      showAdviceBookModel: false,
      showHandModel: false,//手动提交估价弹窗
      showinfoModel: false,//信息有误反馈,弹窗
      InfoSubmitObj: {},//信息有误反馈,弹窗-要传的信息
      single: false,
      street: '',//楼栋
      streetLoading: true,//楼栋加载
      houseNumber: '',//房号
      tabHeight: '',
      streetList: [],
      restaurants: [],
      houserestaurants: [],
      timeout: null,
      lpCode: '',
      ldCode: '',
      houseCode: '',
      roomNumberList: [],
      //输入时的加载状态
      loading: false,
      city: '',//城市默认
      cityObj: {},
      clickCommunObj: {},//点击小区时获取到的一些信息
      communityObj: {},//点击房号时获取到的一些信息
      communityForm: {//根据小区估价相关信息
        buildingArea: '', //面积
        orientation: '', //朝向
        roomCnt: '', //房间数量
        parlourCnt: '', //客厅数量
        kitchenCnt: '', //厨房数量
        toiletCnt: '', //卫生间数量
        floors: '', //楼层
        totalFloors: '', //总楼层
        completionYear: '', //年代
        isHaveLift: '', //电梯

      },
      addressForm: {//根据地址估价
        buildingArea: '', //面积
        orientation: '', //朝向
        roomCnt: '', //房间数量
        parlourCnt: '', //客厅数量
        kitchenCnt: '', //厨房数量
        toiletCnt: '', //卫生间数量
        floors: '', //楼层
        totalFloors: '', //总楼层
        completionYear: '', //年代
        isHaveLift: '', //电梯
      },
      certAddressObj: {//根据地址信息，选择时获取到的一些信息

      },
      baseForm: {
        buildingArea: '', //面积
        orientation: '', //朝向
        roomCnt: '', //房间数量
        parlourCnt: '', //客厅数量
        kitchenCnt: '', //厨房数量
        toiletCnt: '', //卫生间数量
        floors: '', //楼层
        totalFloors: '', //总楼层
        completionYear: '', //年代
        isHaveLift: '', //电梯
      },
      cityList: [

      ],
      //是否显示历史记录
      showHistory: true,
      //模糊查询小区列表
      communityList: [],
      //定时器
      timer: null,
      foShanCenter: {//佛山市政府经纬度
        lng: 113.128212,
        lat: 23.027216
      },
      dongGuanCenter: {//东莞市政府经纬度
        lng: 113.758184,
        lat: 23.027314
      },
      guangzhouCenter: {//广州市 中和广场经纬度
        lng: 113.335593,
        lat: 23.119767,
      },
      //地图
      map: null,
      // 地图标注icon图片
      liveIcon: require("@/assets/image/map/localNew.png"),
      // liveIconActive: require("@/assets/image/map/live.png"),
      // closeIcon: require("@/assets/image/map/close.png"),
      // overlayCloseIcon: require("@/assets/image/map/overLay_close.svg"),
      //地图信息
      mapData: {
        zoom: 17,
        center: {
          lng: 113.335593,
          lat: 23.119767,
        },
      },

    };
  },
  computed: {
    CityLaction() {
      return this.$store.state.cityLaction.cityLaction || localStorage.getItem('cityLaction')
    },
  },
  watch: {
    addressDisabled(val) {

      if (val) {
        this.tabValue = '小区估价'

        //
      }
    },
    showHistory(val) {
      let silice = document.getElementsByClassName("silice-box");
      if (!val) {
        silice[0].style = 'color:#0077FF;padding-left:14px;margin-top:24px;margin-bottom:30px;display:block ;cursor: pointer; z-index:99999'
      } else {
        silice[0].style = 'display:none'
      }
    },
    street(val) {
      if (val) {
        this.houseCode = ''
        this.houseNumber = ''
        this.communityForm = _.cloneDeep(this.baseForm)
      }
    },

  },
  created() {

  },
  mounted() {
    this.setCityList()

    let params = this.$route.params
    if (params.communityName) {
      this.mapPageMsg(params)
    }
    this.getSelectHistory()
    this.showNocomty()
    this.loadMap(BMap)
    this.getcheckAuthList()

  },
  methods: {
    async getcheckAuthList() {//获取城市系统相关权限列表
      let params = {
        cityCode: this.cityObj.cityCode
      }
      let res = await checkAuth(params)
      let addressName = 'certSelectCondition'
      let addressAuth = ''
      let functionAuths = res.functionAuths
      // 遍历functionAuths，找到addressName与interfaceName相等时，authorize为1返回true，否则返回false
      functionAuths.forEach(item => {
        if (item.interfaceName === addressName) {
          addressAuth = item.authorize === 1 ? true : false
        }
      })
      this.addressDisabled = !addressAuth

    },

    tabClick(e) {//选项卡切换
      let that = this
      let _params = {
        event: 'click',
        isButton: false,
        log: e,
        os: navigator.userAgent,
        platform: '数据平台PC',
        route: that.$route.path,
        title: that.$route.meta.title,
        token: that.$store.state.token ? that.$store.state
          .token : '',
        user_id: that.$store.state.userId ? that.$store
          .state
          .userId : '',
      }
      data_user_operation_log_in(_params)
    },
    goMap() {//去地图估价页
      let params = {}

      if (this.communityName == '') {
        this.clickCommunObj = {}
      }
      this.$router.push({
        name: '地图估价',
        params: {
          center: {
            lat: this.clickCommunObj.lpLatBaidu,
            lng: this.clickCommunObj.lpLngBaidu
          }
        }
      })

      // if (this.clickCommunObj.lpLatBaidu!==undefined)  {
      //   params.center = {
      //     lat: this.clickCommunObj.lpLatBaidu,
      //     lng: this.clickCommunObj.lpLngBaidu
      //   }
      // } else {
      //   params = null
      // }


    },
    clickCommunityErrMsg() {//小区-信息有误去反馈
      this.isCommunity = true
      this.showinfoModel = true
      this.InfoSubmitObj = Object.assign({}, this.communityObj, this.clickCommunObj)
    },
    clickCertAddressErrMsg() {//根据地址-信息有误去反馈
      this.isCommunity = false

      this.showinfoModel = true
      this.InfoSubmitObj = Object.assign({}, this.certAddressObj)
    },
    closeModel() {//建议书弹框 关闭
      this.single = true
    },
    loadMap(BMap) {//地图打点
      let that = this;
      that.map = new BMap.Map("Bmap");
      const myPoint = new BMap.Point(
        that.mapData.center.lng,
        that.mapData.center.lat
      );
      // that.map.centerAndZoom(myPoint, 7);
      // 初始化地图， 设置中心点坐标和地图级别
      that.map.centerAndZoom(myPoint, that.mapData.zoom);
      that.map.enableScrollWheelZoom(true);
      const icon = new BMap.Icon(that.liveIcon, new BMap.Size(34, 58), {
        anchor: new BMap.Size(25, 60)
      });
      icon.setSize(new BMap.Size(34, 58));
      icon.setImageSize(new BMap.Size(34, 58));
      that.map.enableScrollWheelZoom();
      let marker = new BMap.Marker(myPoint, {
        icon,
      });
      marker.addEventListener("click", () => {
        that.map.centerAndZoom(myPoint, 17);
      });
      that.map.addOverlay(marker);
    },

    async getCreatData(e) {//获取根据地址输入 的信息
      // this.addressForm = res
      this.clearAddressObj()
      this.certAddress = ''
      if (!e) {
        this.clearAddressObj()
        return
      }

      let cityCode = e.cityCode
      let cityList = this.cityList
      let result = cityList.some(item => item.cityCode == cityCode)
      if (!result) {
        this.$msg.error({
          text: '未开通该城市权限'
        })
        this.$refs.comInput.input = ''

        return
      }
      let params = {
        houseCode: e.houseCode,
        cityCode
      }
      let res = await collectHouseAttribute(params)
      this.city = res.cityCode.toString()

      let cityObj = cityList.find(item => item.cityCode == cityCode)
      sessionStorage.setItem('cityObj', JSON.stringify(cityObj))
      this.$refs.CityInputRef.childChange()

      this.cityChange(this.city)
      this.certAddressObj = res
      this.certAddress = res.certAddress
      let obj = res
      this.mapData.center.lng = obj.lpLngBaidu ? obj.lpLngBaidu : 113.335593
      this.mapData.center.lat = obj.lpLatBaidu ? obj.lpLatBaidu : 23.119767
      this.loadMap(BMap);
      if (!res) {
        this.addressForm = _.cloneDeep(this.baseForm)
        return
      }
      if (res.isHaveLift === true || res.isHaveLift === '有') {
        res.isHaveLift = '有'
      } else if (res.isHaveLift === false || res.isHaveLift === '无') {
        res.isHaveLift = '无'
      } else {
        res.isHaveLift = ''
      }

      Object.keys(this.addressForm).forEach(key => {
        this.addressForm[key] = res[key] == undefined ? this.addressForm[key] : res[key]
      })
      this.certAddressErrMsg = true

    },
    setCityList() {//设置城市
      // this.cityList = this.$store.state.userAutoCityList.cityList
      //从缓存中获取城市列表
      this.cityList = JSON.parse(localStorage.getItem('userAutoCityList'))
      // this.cityList = CityList.cityList
      sessionStorage.setItem('cityList', JSON.stringify(this.cityList))
      let seeCityObj = JSON.parse(sessionStorage.getItem('cityObj'))
      let guangzhouObj = this.cityList.find(item => item.cityCode === '440100')

      if (!seeCityObj || !seeCityObj.isAuth) { // 如果没有缓存或缓存的城市没有权限
        seeCityObj = guangzhouObj.isAuth ? guangzhouObj : this.cityList.find(item => item.isAuth)
      } else {
        seeCityObj = this.cityList.find(item => item.cityCode === seeCityObj.cityCode)
      }

      // let hasPermission = this.cityList.some(item => {//是否有权限
      //   return item.authority == true
      // })
      // if (seeCityObj && hasPermission && seeCityObj.authority) {//如果已经缓存过了，且有权限，就选择缓存的
      //   this.cityObj = seeCityObj
      // } else if (!hasPermission) {//如果没有权限，就选择第一个
      //   this.cityObj = this.cityList[0]

      // } else {
      //   this.cityObj = this.cityList.find(item => item.authority)//如果没有缓存过，且不是全部没权限，就选择第一个有权限的
      // }
      //  this.cityObj = this.cityList[0]
      this.cityObj = seeCityObj || this.cityList[0]

      this.city = this.cityObj.cityCode
      sessionStorage.setItem('cityObj', JSON.stringify(this.cityObj))
      this.$store.commit("changeCityObj", this.cityObj)
      this.cityChange(this.city)
    },
    cityOptionclick(e) {//城市选择
    },
    childCityChange(obj) {
      this.cityChange(obj.cityCode)
    },
    cityChange(cityCode) {
      this.cityList.map(item => {
        if (cityCode == item.cityCode) {
          this.cityObj = item
          sessionStorage.setItem('cityObj', JSON.stringify(item))
          this.$store.commit("changeCityObj", item)
        }
      })
      this.getcheckAuthList()
      let center = {
        lng: 113.335593,
        lat: 23.119767,
      }
      if (this.CityLaction[cityCode]) {
        center = this.CityLaction[cityCode];
        // center = this.CityLaction.find(item => item.cityCode == cityCode).laction;

      }
      this.mapData.center = {
        lat: center.lat,
        lng: center.lng
      };
      this.loadMap(BMap);
      this.clearObj()
      this.clearAddressObj()
    },
    clickCity(e) {//点击城市
    },
    async ldQuerySearch(queryString, cb) {
      while (this.restaurants.length == 0 && this.communityName) {
        // 等待一段时间再重新检查
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
      let restaurants = this.restaurants;
      let results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据

      cb(results);
    },
    async houseQuerySearch(queryString, cb) {//房号搜索
      // this.houserestaurants = await getHouseNumber({})
      while (this.houserestaurants.length == 0) {
        // 等待一段时间再重新检查
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
      let restaurants = this.houserestaurants;
      let results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      cb(results);
      // 调用 callback 返回建议列表的数据
      //  clearTimeout(this.timeout);
      //   this.timeout = setTimeout(() => {
      //     cb(results);
      //   }, 300000 );
      // cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) > -1);
        // return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },

    assessment() {//小区估价-按钮
      // let baseRef = this.$refs.baseForm.$refs.formValidate
      // baseRef.validate((valid) => {//表单校验
      //   if (valid) {
      //   } else {
      //   }
      // })
      let inputObj = this.$refs.baseForm.formValidate//子组件信息

      if (!this.communityName) {
        this.$msg.error({ text: "请输入小区名称" });
        return
      }
      if (!this.street) {
        this.$msg.error({ text: "请输入楼栋号" });
        return
      }
      if (!this.houseNumber) {
        this.$msg.error({ text: "请输入房号" });

        return
      }

      if (!inputObj.buildingArea) {
        this.$msg.error({ text: "请输入面积" });
        return
      }
      if (!this.single) {
        this.$msg.error({ text: "请先阅读并勾选《房屋智能估价系统使用声明》" });
        return
      }
      //这里填校验逻辑
      this.saveSampleResultDetail()
    },
    async saveSampleResultDetail() {//根据小区估价-保存
      this.assessmentLoading = true
      this.InfoSubmitObj = Object.assign({}, this.communityObj, this.clickCommunObj)

      let { cityCode, cityFullName, cityShortName, provinceCode, provinceFullName, provinceShortName } = this.cityObj
      let inputObj = this.$refs.baseForm.formValidate//子组件信息
      let communityObj = this.communityObj
      let params = {
        "cityCode": cityCode,
        "cityFullName": cityFullName,
        "cityShortName": cityShortName,
        "countyCode": communityObj.countyCode,
        "countyFullName": communityObj.countyFullName,
        "countyShortName": communityObj.countyShortName,
        "provinceCode": provinceCode, //，默认
        "provinceFullName": provinceFullName,
        "provinceShortName": provinceShortName,
        "ldCode": this.ldCode,
        "evlType": 0, //估价方式: 0根据小区估价 1根据地址估价 2手动提交估价
        "lpCode": this.lpCode,
        "houseCode": this.houseCode,
        "inputRoomNum": this.houseNumber, //房号名
        "inputLdName": this.street, //楼栋名
        "inputLpName": this.communityName, //小区名
        "inputBuiltArea": inputObj.buildingArea, //手动输入面积
        "inputOrientation": inputObj.orientation, //手动输入的朝向
        "inputRoomCnt": inputObj.roomCnt, //手动输入房数
        "inputParlourCnt": inputObj.parlourCnt, //手动输入的厅数
        "inputKitchenCnt": inputObj.kitchenCnt, //手动输入厨房数
        "inputToiletCnt": inputObj.toiletCnt, //手动输入卫生数
        "inputCompletionYear": inputObj.completionYear, //手动输入的年代
        "inputTotalFloors": inputObj.totalFloors, //手动输入总楼层
        "inputFloors": inputObj.floors, //手动输入的楼层
        "inputIsHaveLift": inputObj.isHaveLift, //手动输入的电梯
        "platform": 1,
      }
      if (inputObj.completionYear) {
        let date = new Date(inputObj.completionYear);
        params.inputCompletionYear = date.getFullYear().toString()
      }

      if (params.inputIsHaveLift === '') {
        params.inputIsHaveLift = ''
      } else if (params.inputIsHaveLift === '无') {
        params.inputIsHaveLift = false
      }
      else {
        params.inputIsHaveLift = params.inputIsHaveLift ? true : false
      }
      // this.saveInfoSubmt()

      // this.$refs.infoSubmitRef.saveSampleResultDetail()
      let res = await sampleResultDetail(params).catch(err => {
        // this.$msg.error(err.data);
        this.assessmentLoading = false
      })
      this.assessmentLoading = false
      // if(!res.flag){
      //   this.$msg.error({ text: res.data });
      //   return
      // }

      this.$router.push({
        path: '/Empowerment/pledge/houseProperty/resultValuation',
        query:
        {
          cityCode: cityCode,
          sampleHistoryId: res.sampleHistoryId
        }
      })
    },
    addressValue() {//根据地址估价， 保存信息
      let inputObj = this.$refs.baseAddressForm.formValidate//子组件信息
      let comInput = this.$refs.comInput.input
      if (!comInput) {
        this.$msg.error({ text: "请输入房屋地址" });
        return
      }

      if (!inputObj.buildingArea) {
        this.$msg.error({ text: "请输入面积" });
        return
      }
      if (!this.single) {
        this.$msg.error({ text: "请先阅读并勾选《房屋智能估价系统使用声明》" });
        return
      }
      this.saveAddressValue()


    },
    async saveAddressValue() {//根据地址估价-保存
      let comInput = this.$refs.comInput.input
      this.InfoSubmitObj = Object.assign({}, this.certAddressObj)

      this.assessmentLoading = true
      let { cityCode, cityFullName, cityShortName, provinceCode, provinceFullName, provinceShortName } = this.cityObj
      let inputObj = this.$refs.baseAddressForm.formValidate//子组件信息
      let communityObj = this.certAddressObj

      let params = {
        "cityCode": cityCode,
        "cityFullName": cityFullName,
        "cityShortName": cityShortName,
        "countyCode": communityObj.countyCode,
        "countyFullName": communityObj.countyFullName,
        "countyShortName": communityObj.countyShortName,
        "provinceCode": provinceCode, //，默认
        "provinceFullName": provinceFullName,
        "provinceShortName": provinceShortName,
        "ldCode": communityObj.ldCode,
        "evlType": 1, //估价方式: 0根据小区估价 1根据地址估价 2手动提交估价
        "lpCode": this.lpCode,
        "houseCode": communityObj.houseCode,
        "inputRoomNum": communityObj.houseNumber, //房号名
        "inputLdName": communityObj.street, //楼栋名
        "inputCertAddress": comInput,//地址信息
        "inputBuiltArea": inputObj.buildingArea, //手动输入面积
        "inputOrientation": inputObj.orientation, //手动输入的朝向
        "inputRoomCnt": inputObj.roomCnt, //手动输入房数
        "inputParlourCnt": inputObj.parlourCnt, //手动输入的厅数
        "inputKitchenCnt": inputObj.kitchenCnt, //手动输入厨房数
        "inputToiletCnt": inputObj.toiletCnt, //手动输入卫生数
        "inputCompletionYear": inputObj.completionYear, //手动输入的年代
        "inputTotalFloors": inputObj.totalFloors, //手动输入总楼层
        "inputFloors": inputObj.floors, //手动输入的楼层
        "inputIsHaveLift": inputObj.isHaveLift, //手动输入的电梯
        "platform": 1,

      }
      if (inputObj.completionYear) {
        let date = new Date(inputObj.completionYear);
        params.inputCompletionYear = date.getFullYear().toString()
      }
      if (params.inputIsHaveLift === '') {
        params.inputIsHaveLift = ''
      } else if (params.inputIsHaveLift === '无') {
        params.inputIsHaveLift = false
      }
      else {
        params.inputIsHaveLift = params.inputIsHaveLift ? true : false
      }

      // this.saveInfoSubmt()
      let res = await sampleResultDetail(params).catch(err => {
        // this.$msg.error(err.data);
        this.assessmentLoading = false
      })
      this.assessmentLoading = false
      this.$router.push({
        path: '/Empowerment/pledge/houseProperty/resultValuation',
        query:
        {
          cityCode: cityCode,
          sampleHistoryId: res.sampleHistoryId
        }
      })
    },

    async saveInfoSubmt() {//保存信息
      let { cityCode } = this.cityObj
      let params = {

      }
      let inputObj = this.$refs.baseForm.formValidate//子组件信息
      params = this.InfoSubmitObj
      params.ldCode = this.ldCode
      for (let key in inputObj) {
        params[key] = inputObj[key]
      }
      if (inputObj.completionYear) {
        let date = new Date(inputObj.completionYear);
        params.completionYear = date.getFullYear().toString()
      }

      if (params.isHaveLift === '') {
        params.isHaveLift = ''
      } else if (params.isHaveLift === '无') {
        params.isHaveLift = false
      }
      else {
        params.isHaveLift = (params.isHaveLift == '有' || params.isHaveLift == true) ? true : false
      }
      let addparams = {
        certificateInfo: params,
        cityCode,
        platform: 1
      }
      // certificateEdit(addparams)
    },

    clearObj() {//重置根据小区估价的一些信息
      this.streetList = []
      this.restaurants = this.streetList
      this.communityName = ''
      this.communityForm = _.cloneDeep(this.baseForm)
      this.street = ''
      this.houseNumber = ''
      this.communityErrMsg = false
      this.clickCommunObj = {}

    },
    clearAddressObj() {//重置根据地址估价的一些信息
      this.certAddress = ''

      this.addressForm = _.cloneDeep(this.baseForm)
      this.certAddressErrMsg = false

      // this.certAddressErrMsg = false

    },
    async mapPageMsg(obj) {//从地图页跳过来的信息
      this.lpCode = obj.lpCode
      this.communityName = obj.communityName

      this.clickCommunObj = obj
      // this.cityChange(obj.cityCode)
      this.city = this.cityObj.cityCode

      let { cityCode } = this.cityObj

      let params = {
        lpCode: obj.lpCode,
        cityCode: cityCode,
        communityName: this.communityName
      }
      let res = await getStreet(params)
      res.map(item => {
        item.value = item.ldShortName
        return item
      })
      this.streetList = res
      this.restaurants = this.streetList
      this.mapData.center.lng = obj.center.lpLngBaidu ? obj.center.lpLngBaidu : 113.335593
      this.mapData.center.lat = obj.center.lpLatBaidu ? obj.center.lpLatBaidu : 23.119767
      this.loadMap(BMap);
    },
    noLook(val) {//点击找不到小区或地址
      this.communityName = val
      setTimeout(() => {
        this.showHandModel = true
      }, 300)
      // this.showHandModel = true

    },
    creatNoLook(val) {//点击找不到地址
      this.certAddress = val
      this.showHandModel = true
    },


    async getComData(obj) {//根据小区搜索 获取到的信息

      this.clearObj()
      this.communityName = ''
      let cityCode = obj.cityCode
      let cityList = this.cityList
      let result = cityList.some(item => item.cityCode == cityCode)
      if (!result) {
        this.$msg.error({
          text: '未开通该城市权限'
        })
        this.$refs.comInputCommunity.input = ''

        return
      }
      let cityObj = cityList.find(item => item.cityCode == cityCode)
      this.city = obj.cityCode.toString()

      sessionStorage.setItem('cityObj', JSON.stringify(cityObj))
      this.$refs.CityInputRef.childChange()
      this.cityChange(this.city)
      this.clickCommunObj = obj
      this.lpCode = obj.lpCode
      this.communityName = obj.communityName
      let params = {
        lpCode: obj.lpCode,
        cityCode: obj.cityCode,
        communityName: this.communityName
      }
      let res = await getStreet(params)
      res.map(item => {
        item.value = item.ldShortName
        return item
      })
      this.streetList = res
      this.restaurants = this.streetList
      this.mapData.center.lng = obj.lpLngBaidu ? obj.lpLngBaidu : 113.335593
      this.mapData.center.lat = obj.lpLatBaidu ? obj.lpLatBaidu : 23.119767
      this.loadMap(BMap);
    },
    async ldHandleSelect(e) {//选择楼栋
      this.houserestaurants = [] //选择时先将房号列表置为空
      this.houseCode = ''
      this.houseNumber = ''
      this.communityErrMsg = false
      this.communityForm = _.cloneDeep(this.baseForm)
      let ldCode = e.ldCode

      this.ldCode = e.ldCode
      let { cityCode, provinceCode } = this.cityObj
      let { countyCode } = this.clickCommunObj
      let params = {
        cityCode,
        provinceCode,
        ldCode,
        countyCode,
        ldShortName: e.ldShortName,
        lpName: this.clickCommunObj.communityName
      }
      let res = await getHouseNumber(params)
      // console.log('res', res)
      if (res.length == 0) {
        this.roomNumberList = [
          {
            houseCode: "",
            roomNumber: "无",
            value: "无房号"
          }
        ]
        this.houserestaurants = this.roomNumberList
        return
      }
      res.map(item => {
        item.value = item.roomNumber
        return item
      })
      this.roomNumberList = res
      this.houserestaurants = this.roomNumberList

    },
    async streetblur() {//选择楼栋失焦
      let value = this.street
      this.communityErrMsg = false
      let ldCode = ''
      let ldShortName = ''
      this.streetList.map(item => {//判断手动输入的楼栋是否在系统内
        if (item.value == value) {
          ldCode = item.ldCode
          ldShortName = item.ldShortName
        }
      })
      let { cityCode, provinceCode } = this.cityObj
      let { countyCode } = this.clickCommunObj
      let params = {
        cityCode,
        provinceCode,
        ldCode,
        countyCode,
        ldShortName: ldShortName,
        lpName: this.clickCommunObj.communityName
      }
      if (ldCode) {
        let res = await getHouseNumber(params)
        res.map(item => {
          item.value = item.roomNumber
          return item
        })
        this.roomNumberList = res
        this.houserestaurants = this.roomNumberList
      } else {
        this.ldCode = ''
        this.houseCode = ''
        this.houseNumber = ''
        this.communityForm = _.cloneDeep(this.baseForm)
        this.roomNumberList = [
          {
            houseCode: "",
            roomNumber: "无",
            value: "无房号"
          }
        ]
        this.houserestaurants = this.roomNumberList
      }
    },
    async houseHandleSelect(e) {//选择房号
      this.houseNumber = e.roomNumber
      if (e.roomNumber == '无房号') {
        return
      }
      let { cityCode } = this.cityObj
      this.houseCode = e.houseCode
      let params = {
        houseCode: e.houseCode,
        cityCode
      }
      let res = await collectHouseAttribute(params)
      this.communityObj = res
      if (res.isHaveLift === true) {
        res.isHaveLift = '有'
      } else if (res.isHaveLift === false) {
        res.isHaveLift = '无'
      } else {
        res.isHaveLift = ''
      }
      Object.keys(this.communityForm).forEach(key => {
        this.communityForm[key] = res[key] == undefined ? this.communityForm[key] : res[key]
      })
      this.communityErrMsg = true

    },
    async houseNumberblur() {//房号输入框失焦
      // communityErrMsg
      let value = this.houseNumber
      let { cityCode } = this.cityObj

      let houseCode = ''
      this.houserestaurants.map(item => {//判断手动输入的房号是否在系统内
        if (item.value == value) {
          houseCode = item.houseCode
        }
      })
      if (houseCode) {
        this.communityErrMsg = true
        let res = await collectHouseAttribute({ houseCode, cityCode })
        this.communityObj = res
        if (res.isHaveLift === true) {
          res.isHaveLift = '有'
        } else if (res.isHaveLift === false) {
          res.isHaveLift = '无'
        } else {
          res.isHaveLift = ''
        }
        Object.keys(this.communityForm).forEach(key => {
          this.communityForm[key] = res[key] == undefined ? this.communityForm[key] : res[key]
        })
      } else {
        this.houseCode = ''
        this.communityErrMsg = false
        this.communityForm = _.cloneDeep(this.baseForm)
      }
    },



    clickNoComunity() {
      // this.communityName = ''

    },

    setHistory(value) {//设置搜索历史
      let obj = {}
      this.communityList.map(item => {
        if (item.value == value) {
          obj = item
        }
      })
      let key = 'searchHistory_v1'
      let history = localStorage.getItem(key) ? JSON.parse(localStorage.getItem(
        key)) : []
      history.unshift({
        value: obj.value,
        ...obj
      })
      history = history.slice(0, 5)
      localStorage.setItem(key, JSON.stringify(history))
    },
    //当搜索词为空时，设置为历史
    onQueryChange(e) {
      this.showHistory = false
      if (e === '') {
        if (this.timer) {
          clearTimeout(this.timer)
        }
        this.getSelectHistory()
      }
    },
    //获取搜索历史
    async getSelectHistory() {
      let key = 'searchHistory_v1'
      let history = localStorage.getItem(key) ? JSON.parse(localStorage.getItem(
        key)) : []
      history = [...new Set(history)];
      history = this.uniqueFunc(history, 'value')
      this.communityList = history.slice(0, 5)
      this.showHistory = true
    },
    uniqueFunc(arr, value) { //数组去重
      const res = new Map();
      return arr.filter((item) => !res.has(item[value]) && res.set(item[value], 1));
    },
    //删除历史
    deleteSelectHistory(obj) {
      let key = 'searchHistory_v1'

      let history = this.communityList.filter((item) => {
        return obj.id !== item.id
      })
      this.communityList = history
      localStorage.setItem(key, JSON.stringify(history))


    },
    showNocomty() {
      // let silice =  document.getElementsByClassName("ivu-select-dropdown-list");
      let silice = document.getElementsByClassName("ivu-select-dropdown");
      // let silice = document.getElementsByClassName("el-autocomplete-suggestion__wrap");
      let box = `
       <div  class="silice-box ivu-select-item"  style=' display:none ' >找不到你想要的小区，手动提交估价</div>
  `
      let creatBox = document.createElement('div')
      creatBox.addEventListener('click', function () {
      }, true)
      // creatBox.onclick = () => {
      // }

      creatBox.innerHTML = box
      silice[1].appendChild(creatBox)


    },
    //模糊查询
    async getCommunityList(query) {//查询小区
      if (query !== '') {
        if (this.timer) {
          clearTimeout(this.timer)
        }
        this.timer = setTimeout(async () => {
          this.loading = true;
          let params = {
            condition: query
          }
          let data = await communitySelectCondition(params)
          if (data) {
            // this.communityList = []
            data.map(item => {
              let obj = {}
              obj = item
              obj.value = item.lpCode
              // obj.label = item.countyFullName
              obj.label = item.communityName

              return obj
            })
            this.communityList = data
            this.loading = false;
          }
        }, 500)

      } else {
        this.communityList = [];
      }
    },
  }
};
</script>

<style scoped lang="scss">
.body-box {
  background: #EFF1F6;
  // height: 100%;
  min-height: 100%;
  padding: 24px;

  .main-content {
    background: #FFFFFF;
    // height: 100%;
    min-height: 100%;
    // min-height: 958px;
    min-width: 1400px;
    border-radius: 10px 10px 10px 10px;
    padding: 24px;
    padding-bottom: 90px;

    .tabs {
      margin-left: 54px;
      margin-top: 40px;
      min-height: 600px;
      width: 580px;

      .bottom-box {

        margin-top: 32px;

        margin-left: 78px;

        .bottom-bit {
          margin-top: 20px;

        }
      }

      .tab1 {
        // height: 400px;
        // overflow-y: auto;
        margin-top: 20px;

      }

      .map-text {
        // display: inline;
        color: #0077FF;
        position: absolute;
        top: 0;
        right: 28px;
        cursor: pointer;
      }
    }

    // margin: 24px;
  }

}

.nocity-authority {

  border-radius: 4px;
  opacity: 1;
  margin-left: 11px;
  height: 20px;
  background: #F3F3F3;
  color: #A7A7A7;
  font-size: 12px;
  line-height: 14px;
  padding: 3px 6px;
}

#Bmap {
  height: 100%;
  // min-width: 748px;
  border-radius: 10px;
  width: 100%;
  background: #eee;
}

/deep/.ivu-select-single .ivu-select-input {

  width: 90%;
}

/deep/ .ivu-tabs-nav {
  margin-left: 84px;
}

/deep/ .ivu-tabs-nav .ivu-tabs-tab {
  padding: 0;
  padding-bottom: 23px;
  // margin-right: 106px;
  font-size: 20px;
  padding-right: 106px;
}

/deep/ .ivu-tabs-ink-bar {
  width: 64px !important;
  left: 8px;
}

/deep/ .ivu-tabs-bar {
  border: 0;
}

/deep/ .el-icon-loading {
  color: #0077FF;

  font-size: 30px;
}

/deep/ .el-autocomplete-suggestion.is-loading li {
  font-size: 30px !important;

}
</style>
<style>
.el-icon-loading {
  color: #0077FF;
}

.el-autocomplete-suggestion.is-loading li {
  font-size: 30px !important;

}
</style>
