import {
    get,
    post,
    _delete
} from '@/utils/http'
import axios from 'axios'; // 引入axios
import { downFile } from '@/api/public.js'

const serviceEvaluation = '/service-evaluation/intelligentEvaluation/v3/'

//押品统计
export function yptj(params) {
    return get({
        url: '/service-insight/pledge/yptj',
        params
    })
}

//区域分布统计
export function ypQyfb(params) {
    return get({
        url: '/service-insight/pledge/ypQyfb',
        params
    })
}

//押品管理（押品查询 面积维度）
export function mj(params) {
    return get({
        url: '/service-insight/pledge/ypinfo/dim/mj',
        params
    })
}

//押品管理（估值修正）
export function gzxz(params) {
    return post({
        url: '/service-insight/pledge/gzxz',
        params
    })
}
//押品管理（预警值）
export function yjz(params) {
    return post({
        url: '/service-insight/pledge/yjz',
        params
    })
}
//押品管理（最高抵质押率）
export function zgdzyl(params) {
    return post({
        url: '/service-insight/pledge/zgdzyl',
        params
    })
}
//押品管理（押品详情 确认评估结果）
export function qrpgjg(params) {
    return get({
        url: '/service-insight/pledge/qrpgjg',
        params
    })
}

//任务列表
export function rwlb(params) {
    return post({
        url: '/service-insight/pledge/rwlb',
        params
    })
}
//任务详情
export function task(params) {
    return get({
        url: '/service-insight/pledge/rwlb/task',
        params
    })
}

//任务详情 押品列表
export function task_yp_list(params) {
    return post({
        url: '/service-insight/pledge/rwlc/yp',
        params
    })
}

//押品管理（模板下载）
export function getDownloadTemplate(params) {
    return get({
        url: '/service-insight/pledge/getDownloadTemplate',
        params
    })
}
//押品管理（任务新增 文件上传）
export function add(params) {
    return post({
        url: '/service-insight/pledge/rwlb/add',
        params
    })
}

//押品管理（评估结果>单套详情(左上)）
export function one_yp(params) {
    return post({
        url: '/service-insight/pledge/oneYp',
        params
    })
}
//押品管理（评估结果>参考案例(成交案例)）
export function yp_cjal(params) {
    return post({
        url: '/service-insight/pledge/ypCjal',
        params
    })
}
//押品管理（评估结果>参考案例(挂牌案例)）
export function yp_gpal(params) {
    return post({
        url: '/service-insight/pledge/ypGpal',
        params
    })
}
//周边小区
export function yp_zbxq(params) {
    return post({
        url: '/service-insight/pledge/ypZbxq',
        params
    })
}
//小区详情
export function yp_xqxq(params) {
    return post({
        url: '/service-insight/pledge/ypXqxq',
        params
    })
}

//价格走势
export function yp_jgzs(params) {
    return post({
        url: '/service-insight/pledge/ypJgzs',
        params
    })
}



//押品管理（评估结果>本小区押品列表）
export function xqyp_list(params) {
    return post({
        url: '/service-insight/pledge/xqyp_list',
        params
    })
}
//押品管理（评估结果>土地市场)
export function yp_td(params) {
    return post({
        url: '/service-insight/pledge/ypTd',
        params
    })
}
//押品管理（评估结果>商品房市场)
export function yp_spf(params) {
    return post({
        url: '/service-insight/pledge/ypSpf',
        params
    })
}
//押品管理（评估结果>行政区)
export function yp_zxq(params) {
    return post({
        url: '/service-insight/pledge/zxq',
        params
    })
}
//押品管理（评估结果>行政区)
export function yp_xq_zxq(params) {
    return post({
        url: '/service-insight/pledge/xq/zxq',
        params
    })
}

//押品管理（评估结果>物业分类)
export function yp_wylx(params) {
    return get({
        url: '/service-insight/pledge/wylx',
        params
    })
}
//押品管理（押品土地市场统计数据)
export function yp_tdtj(params) {
    return post({
        url: '/service-insight/pledge/tdtj',
        params
    })
}



//押品管理（评估结果>司法拍卖)
export function yp_sfpm(params) {
    return post({
        url: '/service-insight/pledge/ypSfpm',
        params
    })
}
//押品管理（评估结果>产权交易所/第三方交易平台)
export function yp_cqjys(params) {
    return post({
        url: '/service-insight/pledge/ypCqjys',
        params
    })
}
//押品管理（评估结果>押品详情）
export function yp_xq(params) {
    return post({
        url: '/service-insight/pledge/ypXq',
        params
    })
}
//押品管理（评估结果>单套详情(评估历史)）
export function yp_history(params) {
    return post({
        url: '/service-insight/pledge/ypHistory',
        params
    })
}
//押品管理（评估结果>单套详情(业内评估)）
export function yp_ynpg(params) {
    return post({
        url: '/service-insight/pledge/ypYnpg',
        params
    })
}
//押品查询
export function ypinfo(params) {
    return post({
        url: '/service-insight/pledge/ypinfo/list',
        params
    })
}
//押品管理（评估结果>根据任务id获取押品信息
export function recordByTaskId(params) {
    return get({
        url: '/service-insight/pledge/recordByTaskId',
        params
    })
}


//小区
//押品管理（评估结果>单套详情(评估历史)）
export function yp_xq_history(params) {
    return post({
        url: '/service-insight/pledge/xq/ypHistory',
        params
    })
}
//小区评估
export function yp_xqpg(params) {
    return post({
        url: '/service-insight/pledge/xq/xqpg',
        params
    })
}
//押品管理（评估结果>参考案例(成交案例)）
export function yp_xq_cjal(params) {
    return post({
        url: '/service-insight/pledge/xq/ypCjal',
        params
    })
}
//挂牌
export function yp_xq_gpal(params) {
    return post({
        url: '/service-insight/pledge/xq/ypGpal',
        params
    })
}
//押品管理（评估结果>本小区押品列表）
export function xqyp_xq_list(params) {
    return post({
        url: '/service-insight/pledge/xq/xqyp_list',
        params
    })
}

//押品管理（评估结果>土地市场)
export function yp_xq_td(params) {
    return post({
        url: '/service-insight/pledge/xq/ypTd',
        params
    })
}
//押品管理（评估结果>商品房市场)
export function yp_xq_spf(params) {
    return post({
        url: '/service-insight/pledge/xq/ypSpf',
        params
    })
}

//押品管理（评估结果>司法拍卖)
export function yp_xq_sfpm(params) {
    return post({
        url: '/service-insight/pledge/xq/ypSfpm',
        params
    })
}
//押品管理（评估结果>产权交易所/第三方交易平台)
export function yp_xq_cqjys(params) {
    return post({
        url: '/service-insight/pledge/xq/ypCqjys',
        params
    })
}

export function cs(params) {
    return get({
        url: '/service-insight/pledge/ypinfo/dim/cs',
        params
    })
}
export function xiaoqu(params) {
    return get({
        url: '/service-insight/pledge/ypinfo/dim/xiaoqu',
        params
    })
}


//周边小区
export function yp_xq_zbxq(params) {
    return post({
        url: '/service-insight/pledge/xq/ypZbxq',
        params
    })
}
//小区详情
export function yp_xq_xqxq(params) {
    return post({
        url: '/service-insight/pledge/xq/ypXqxq',
        params
    })
}

//价格走势
export function yp_xq_jgzs(params) {
    return post({
        url: '/service-insight/pledge/xq/ypJgzs',
        params
    })
}

//小区名字模糊查询
export function communitySelectCondition(params) {
    return get({
        url: `${serviceEvaluation}communitySelectCondition`,
        params
    })
}
//根据城市查询行政区及对应坐标
export function countyLocation(params) {
    return get({
        url: `${serviceEvaluation}countyLocation`,
        params
    })
}
//证载地址级联查询/房屋地址
export function certificateDetail_street(params) {
    return post({
        url: `${serviceEvaluation}certificateDetail/street`,
        params
    })
}

//证载地址级联查询/单元
export function certificateDetail_unit(params) {
    return post({
        url: '/service-insight/intelligentEvaluation/certificateDetail/unit',
        params
    })
}

//证载地址级联查询/房号
export function certificateDetail_houseNumber(params) {
    return post({
        url: '/service-insight/intelligentEvaluation/certificateDetail/houseNumber',
        params
    })
}


// //根据房号获取详情
// export function sampleResultDetail(params) {
// 	return get({
// 		url: '/service-insight/intelligentEvaluation/sampleResultDetail',
// 		params
// 	})
// }
//根据房号获取详情
export function sampleResultDetail(params) {
    return post({
        url: `${serviceEvaluation}sampleResultDetail`,
        params
    })
}


// //证载地址编辑
// export function certificateEdit(params) {
// 	return post({
// 		url: '/service-insight/intelligentEvaluation/certificateEdit',
// 		params
// 	})
// }


// //参考案例
// export function exampleList(params) {
// 	return post({
// 		url: '/service-insight/intelligentEvaluation/exampleList',
// 		params
// 	})
// }

// //押品评估历史(分页)
// export function evaluationHistoryPage(params) {
// 	return post({
// 		url: '/service-evaluation/intelligentEvaluation/evaluationHistoryPage',
// 		params
// 	})
// }
//押品评估历史(分页)
export function evaluationHistoryPage(params) {
    return post({
        url: `${serviceEvaluation}evaluationHistoryPage`,

        params
    })
}

//删除历史记录
export const delEvaluationHistory = (params) => {
    return _delete({
        url: `${serviceEvaluation}delEvaluationHistory`,
        params
    })
}

//证载地址收集
export function certificateApply(params) {
    return post({
        url: '/service-insight/intelligentEvaluation/certificateApply',
        params
    })
}
//评估详情
export function certificateById(params) {
    return get({
        url: '/service-insight/intelligentEvaluation/certificateById',
        params
    })
}

//评估详情
export function historyById(params) {
    return get({
        url: '/service-insight/intelligentEvaluation/historyById',
        params
    })
}

// export function addDataUserFeedback(params) {
// 	return post({
// 		url: '/service-insight/intelligentEvaluation/addDataUserFeedback',
// 		params
// 	})
// }

//获取搜索历史下拉
export function communitySearchHistory(params) {
    return get({
        url: '/service-insight/intelligentEvaluation/communitySearchHistory',
        params
    })
}



//删除搜索历史
export function deleteHistory(params) {
    return _delete({
        url: '/service-insight/intelligentEvaluation/deleteHistory',
        params
    })
}

//搜索历史保存
export function saveCommunitySearchHistory(params) {
    return post({
        url: '/service-insight/intelligentEvaluation/saveCommunitySearchHistory',
        params
    })
}


// 证载地址级联查询/房屋地址
export const getStreet = (params) => {
    return get({
        url: `${serviceEvaluation}ldSelectOptions`,
        params
    })
}

//证载地址级联查询/房号
export const getHouseNumber = (params) => {
    return get({
        url: `${serviceEvaluation}houseNumberSelectOptions`,
        params
    })
}

//根据房号id去查询信息
export const collectHouseAttribute = (params) => {
    return get({
        url: `${serviceEvaluation}collectHouseAttribute`,
        params
    })
}
//根据房号id去查询信息
// export const certInfo = (params) => {
// 	return get({
// 		url: `${serviceEvaluation}houseNumberSelectOptions`,
// 		params
// 	})
// }


//热点城市
export const queryHotCity = (params) => {
    return get({
        url: `/service-evaluation/upCityConfigMgr/query-hot-city`,
        params
    })
}
//地址模糊查询
export const certSelectCondition = (params) => {
    return get({
        url: `${serviceEvaluation}certSelectCondition`,
        params
    })
}

//证载地址编辑
export const certificateEdit = (params) => {
    return post({
        url: `${serviceEvaluation}certificateEdit`,
        params
    })
}

//经纬度范围查询楼盘
export const lngLatCommunityList = (params) => {
    return post({
        url: `${serviceEvaluation}lngLatCommunityList/?cityCode=${params.cityCode}`,
        params
    })
}
//税费计算
export const taxCalculator = (params) => {
    return post({
        url: `${serviceEvaluation}taxCalculator`,
        params
    })
}
//查询历史结果
export const resultHistory = (params) => {
    return get({
        url: `${serviceEvaluation}history`,
        params
    })
}
//周边案例
export const exampleList = (params) => {
    return post({
        url: `${serviceEvaluation}exampleList`,
        params
    })
}
//精确查询
export const certByAddress = (params) => {
    return get({
        url: `${serviceEvaluation}certByAddress`,
        params
    })
}

export function addDataUserFeedback(params) {
    return post({
        // url: '/service-insight/intelligentEvaluation/addDataUserFeedback',
        url: `${serviceEvaluation}addDataUserFeedback`,

        params
    })
}

//文件下载

// 下载上一次上传失败的excel表格-下载校验结果
export const downloadErrorMsg = (params) =>
    downFile(
        '/service-analyze/EvlBatchExcelUpload/downloadErrorMsg',
        params,
        "blob",
    )


export const userBatchTaskManagement = (params) => {
    return post({
        url: `/service-evaluation/BatchTaskEvaluation/userBatchTaskManagement`,
        params
    })
}
export const taskStatusStatistics = (params) => {
    return get({
        url: `/service-evaluation/BatchTaskEvaluation/taskStatusStatistics`,
        params
    })
}
export const submitBatchEvlTask = (params) => {
    return post({
        url: `/service-evaluation/BatchTaskEvaluation/submitBatchEvlTask`,
        params
    })
}
export const getEvlReport = (params) => {
    return get({
        url: `/service-evaluation/intelligentEvaluation/v3/getEvlReport`,
        params
    })
}

// 批量-任务删除
export const removeTask = (params) => {
    return _delete({
        url: `/service-evaluation/BatchTaskEvaluation/removeTask`,
        params
    })
}

//批量 详情
export const batchTaskDetail = (params) => {
    return post({
        url: `/service-evaluation/BatchTaskEvaluation/batchTaskDetail`,
        params
    })
}
//批量 详情 估价处理结果
export const queryProcessResult = (params) => {
    return get({
        url: `/service-evaluation/BatchTaskEvaluation/queryProcessResult`,
        params
    })
}
//批量 估价记录
export const batchEvaluationHistoryPage = (params) => {
    return post({
        url: `/service-evaluation/batchEvlHisInfo/batchEvaluationHistoryPage`,
        params
    })
}
//批量 详情 出值
export const outputStatistics = (params) => {
    return get({
        url: `/service-evaluation/BatchTaskEvaluation/outputStatistics`,
        params
    })
}
//批量 历史 删除
export const delHistoryPage = (params) => {
    return get({
        url: `/service-evaluation/batchEvlHisInfo/logicDelete`,
        params
    })
}
//批量 详情 下载
// export const taskDetailExport = (params) => {
//     return get({
//         url: `/service-evaluation/BatchTaskEvaluation/taskDetailExport`,
//         params
//     })
// }

export const taskDetailExport = (params) =>
    downFile(
        '/service-evaluation/BatchTaskEvaluation/taskDetailExport',
        params,
        "blob",
    )
//估价记录下载
export const downLoadExcel = (params) =>
    downFile(
        '/service-evaluation/batchEvlHisInfo/downLoadExcel',
        params,
        "blob",
    )


//批量 详情 出值
export const taskDetailCityName = (params) => {
    return get({
        url: `/service-evaluation/BatchTaskEvaluation/taskDetailCityName`,
        params
    })
}
//功能权限
export const checkAuth = (params) => {
    return get({
        url: `/service-evaluation/function-auth/checkAuth`,
        params
    })
}
//城市列表
export const upCityConfigList = (params) => {
    return get({
        url: `/service-evaluation/upCityConfigMgr/upCityConfigList`,
        params
    })
}
//城市列表树形结构
export const getUserAuthCityTree = (params) => {
    return get({
        url: `/service-evaluation/intelligentEvaluation/v3/platform/getUserAuthCityTree`,
        params
    })
}
//估价历史 城市列表
export const getEvlHisCity = (params) => {
    return get({
        url: `/service-evaluation/intelligentEvaluation/v3/getEvlHisCity`,
        params
    })

}

// /upCityConfigMgr/query-city-lat-lng-list
//城市经纬度查询
export const queryCityLatLngList = (params) => {
    return post({
        url: `/service-evaluation/upCityConfigMgr/query-city-lat-lng-list`,
        params
    })
}

// export const downLoadExcel = (params) => {
//     return post({
//         url: `/service-evaluation/batchEvlHisInfo/downLoadExcel`,
//         params
//     })
// }

