<template>

    <Poptip trigger="hover" v-model="visible" :transfer="true" content="" placement="bottom-start">
        <div class="input_box">
            <div>{{ city }} </div>
            <Icon type="ios-arrow-down" class="ivu-select-arrow" />
        </div>
        <div slot="content">
            <CitySelectBox @cityChange="cityChange" />
        </div>
    </Poptip>

</template>

<script>
import CitySelectBox from '@/views/pledge/components/CitySelectBox.vue'

export default {
    components: {
        CitySelectBox,
    },
    data() {
        return {
            visible: false,
            city: '',
            cityObj: JSON.parse(sessionStorage.getItem('cityObj')),
            cityList: JSON.parse(sessionStorage.getItem('cityList')),

        };
    },

    created() {

    },
    mounted() {
        if (this.cityObj) {
            let { cityFullName } = this.cityObj
            this.city = cityFullName || '广州市'
        }else{
          this.city = '广州市'
        }


    },
    methods: {

childChange(){

let cityObj =JSON.parse(sessionStorage.getItem('cityObj'))
this.city = cityObj.cityFullName
},

        cityChange(obj) {
            this.city = obj.cityFullName;
            this.visible = false;
            this.$emit('childCityChange', obj);
        },
    }
};
</script>

<style scoped lang="scss">
.input_box {
    width: 112px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    padding-left: 8px;
    padding-right: 8px;
    opacity: 1;
    line-height: 30px;
    background: #FFFFFF;
    position: relative;
    cursor: pointer;
    box-shadow: 0px 3px 8px 0px rgba(38, 38, 38, 0.102);
}
</style>
