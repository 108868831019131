const empowerment = {
    path: '/Empowerment',
    name: 'Empowerment',
    component: () => import('../../views/Empowerment.vue'),
    redirect: '/Empowerment/pledge/houseProperty/rapidValuation',
    meta: {
        title: '中思拓-大数据赋能',
        authId: [66],
        function: '大数据赋能'
    },
    children: [
            {
            path: '/Empowerment/pledge/houseProperty/rapidValuation',
            name: '快速估价',
            component: () => import('@/views/pledge/rapidValuation.vue'),
            // redirect: '/Insight/PlaceMarket',
            meta: {
                pageId: 120,
                title: '快速估价',
                fName: '房屋智能估价（单套）',
                authId: [120],
                function: '智能评估｜押品管理'
            }
        },
    {
            path: '/Empowerment/pledge/houseProperty',
            name: '房产智估',
            component: () => import('@/views/pledge/houseProperty.vue'),
            // redirect: '/Empowerment/PlaceMarket',
            meta: {
                pageId: 71,
                title: '中思拓-房产智估（单套）',
                authId: [71],
                function: '智能评估｜押品管理'
            },

        },



        {
            path: '/Empowerment/pledge/houseProperty/mapValuation',
            name: '地图估价',
            component: () => import('@/views/pledge/mapValuation.vue'),
            // redirect: '/Insight/PlaceMarket',
            meta: {
                from: '/Empowerment/pledge/houseProperty/rapidValuation',

                pageId: 120,
                title: '地图估价',
                fName: '房屋智能估价（单套）',
                authId: [120],
                function: '智能评估｜押品管理'
            }
        },
        {
            path: '/Empowerment/pledge/houseProperty/resultValuation',
            name: '估价结果',

            component: () => import('@/views/pledge/resultValuation.vue'),
            // redirect: '/Insight/PlaceMarket',
            meta: {
                pageId: 120,

                title: '评估结果',
                fName: '房屋智能估价（单套）',
                authId: [120],
                function: '智能评估｜押品管理'
            }
        },
        {
            path: '/Empowerment/pledge/pledgeManage/taskResult',
            name: '评估结果',
            component: () => import('@/views/pledge/taskResult.vue'),
            meta: {
                // from: '/Empowerment/pledge/pledgeManage/taskRecord',
                title: '批量评估结果',
                pageId: 129,
                authId: [129],
                fName: '房屋智能估价（批量）',

                function: '智能评估｜押品管理'
            }
        },
        {
            path: '/Empowerment/pledge/houseProperty/recordValuation',
            name: '估价记录',
            component: () => import('@/views/pledge/recordValuation.vue'),
            // redirect: '/Insight/PlaceMarket',
            meta: {
                pageId: 121,
                title: '估价记录',
                fName: '房屋智能估价（单套）',
                authId: [121],
                function: '智能评估｜押品管理'
            }
        },
                        {
                    path: '/Empowerment/pledge/pledgeManage/taskDetail',
                    name: 'taskDetail',
                    meta: {
                        from: '/Empowerment/pledge/pledgeManage/taskManageNew',
                        fName: '房屋智能估价（批量）',
                        pageId: 128,
                        authId: [128],
                        function: '智能评估｜押品管理',
                        keepAlive: true
                    },
                    component: () => import('@/views/pledge/taskDetailNew.vue')
                },
        {
            path: '/Empowerment/pledge/pledgeManage',
            name: '押品管理',
            component: () => import('@/views/pledge/pledgeHome.vue'),
            // redirect: '/Empowerment/pledge/pledgeManage/pledgeManage',
            meta: {
                title: '中思拓-押品管理',
                authId: [70],
                function: '智能评估｜押品管理'
            },
            children: [{
                    path: '/Empowerment/pledge/pledgeManage/pledgeManage',
                    name: '押品管理',
                    component:  () => import('@/views/pledge/pledgeManage.vue'),
                    // redirect: '/Insight/PlaceMarket',
                    meta: {
                        pageId: 76,
                        title: '中思拓-智能评估（多套）',
                        fName: '房屋智能估价（批量）',
                        authId: [70, 72],
                        function: '智能评估｜押品管理'
                    }
                },
                {
                    path: '/Empowerment/pledge/pledgeManage/taskManageNew',
                    name: '任务管理',
                    component: () => import('@/views/pledge/taskManageNew.vue'),
                    meta: {
                        pageId: 128,
                        title: '中思拓-任务管理（批量）',
                        fName: '房屋智能估价（批量）',
                        authId: [128],
                        function: '智能评估｜押品管理'
                    }
                },
                {
                    path: '/Empowerment/pledge/pledgeManage/taskManage',
                    name: '任务管理',
                    component: () => import('@/views/pledge/taskManage.vue'),
                    meta: {
                        pageId: 72,
                        title: '中思拓-任务管理（批量）旧',
                        fName: '房屋智能估价（批量）',

                        authId: [72],
                        function: '智能评估｜押品管理'
                    }
                },
                {
                    path: '/Empowerment/pledge/pledgeManage/taskRecord',
                    name: '估价记录',
                    component: () => import('@/views/pledge/taskRecord.vue'),
                    meta: {
                        pageId: 129,
                        title: '中思拓-估价记录',
                        fName: '房屋智能估价（批 量）',
                        authId: [129],
                        function: '智能评估｜押品管理'
                    }
                },

                {
                    path: '/Empowerment/pledge/pledgeManage/result',
                    name: '评估结果',
                    component: () => import('@/views/pledge/sResult.vue') ,
                    // redirect: '/Insight/PlaceMarket',
                    meta: {
                        pageId: 75,
                        title: '中思拓-评估结果（旧）',
                        authId: [75],
                        fName: '房屋智能估价（批量）',

                        function: '智能评估｜押品管理'
                    }
                },
                {
                    path: '/Empowerment/pledge/pledgeManage/taskDetail',
                    name: '任务详情',
                    meta: {
                        from: '/Empowerment/pledge/pledgeManage/taskManageNew',
                        fName: '房屋智能估价（批量）',
                     pageId: 128,
                        authId: [128],
                        function: '智能评估｜押品管理'
                    },
                    component: () => import('@/views/pledge/taskDetailNew.vue')
                },

                {
                    path: '/Empowerment/pledge/pledgeManage/taskAdd',
                    name: '新增任务',
                    meta: {
                        from: '/Empowerment/pledge/pledgeManage/taskManage',
                        authId: [73],
                        function: '智能评估｜押品管理'
                    },
                    component: () => import('@/views/pledge/taskAdd.vue')
                },
                {
                    path: '/Empowerment/pledge/pledgeManage/pledgeSearch',
                    name: '押品查询',
                    component: () => import('@/views/pledge/pledgeSearch.vue'),
                    // redirect: '/Insight/PlaceMarket',
                    meta: {
                        pageId: 74,
                        title: '中思拓-押品查询',
                        authId: [74],
                        fName: '房屋智能估价（批量）',

                        function: '智能评估｜押品管理'
                    }
                },

                {
                    path: '/Empowerment/pledge/pledgeManage/regionResult',
                    name: '评估结果',
                    component: () => import('@/views/pledge/regionResult.vue'),
                    // redirect: '/Insight/PlaceMarket',
                    meta: {
                        title: '中思拓-评估结果',
                        authId: [75],
                        fName: '房屋智能估价（批量）',

                        function: '智能评估｜押品管理'
                    }
                }
            ]
        },
        {
            path: '/Empowerment/Analysis/Home',
            name: '项目分析',
            component: () => import('@/views/analysis/Home.vue'),
            //redirect: '/Empowerment/Analysis/Home',
            meta: {
                authId: [67],
                  pageId: 68,
                function: '一键看项目'
            },
            children: [{
                    path: 'Home',
                    name: '项目分析首页',
                    component: () => import('@/views/analysis/Home.vue'),
                    meta: {
                        pageId: 68,
                        authId: [68],
                        function: '一键看项目'
                    }
                },
                {
                    path: '/Empowerment/Analysis/Home',
                    name: '项目详情',
                    //redirect: '/Empowerment/Analysis/Home',
                    meta: {
                        authId: [69],
                         pageId: 68,
                        function: '一键看项目'
                    }
                }
            ]
        },
        {
            path: '/Empowerment/MacroEconomy/InternationalEconomy',
            name: '国际经济',
            component: () => import('@/views/InternationalEconomy.vue'),
            meta: {
                authId: [8],
                pageId: 19,
                function: '国际经济'
            }
        }
    ]
}
export default empowerment;